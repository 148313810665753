<template>
    <div class="page-digital-comms-template-sms">
        <div class="row">
            <div class="col-12">
                <div class="board">
                    <app-loader v-if="processing"></app-loader>

                    <app-error v-model="error"></app-error>

                    <app-textarea v-model="sms" label="SMS message" :disabled="processing" ref="sms"></app-textarea>

                    <div class="tags">
                        <button class="btn btn-small btn-cancel" v-for="(tag, key) in tags" :key="key" @click="insertTag(tag)">{{ tag }}</button>
                    </div>

                    <button class="btn btn-small btn-primary" @click="submit" :disabled="!is_changed">Submit</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

import appLoader from '@/components/app-loader'
import appError from '@/components/app-error'
import appTextarea from '@/components/app-textarea'

import errMessage from '@/helpers/errMessage'

export default {
    components: {
        appLoader,
        appError,
        appTextarea,
    },

    data() {
        return {
            sms: '',
            original: '',

            tags: [
                '[[First Name]]',
                '[[Last Name]]',
                '[[Subject]]',
            ],

            error: null,

            processing: false,
        }
    },

    created() {
        this.initSMS()
    },

    methods: {
        initSMS() {
            if (this.template && this.template.SMS) {
                this.sms = this.original = this.template.SMS
            } else {
                this.sms = this.original = ''
            }
        },

        insertTag(tag) {
            const textarea = this.$refs.sms.$refs.textarea
                  textarea.focus()

            // IE support
            if (document.selection) {
                const selection = document.selection.createRange()
                      selection.text = tag

                this.sms = textarea.value
            }
            // Webkit and others
            else if (textarea.selectionStart || textarea.selectionStart == '0') {
                const selection_start = textarea.selectionStart
                const selection_end = textarea.selectionEnd
                
                this.sms = this.sms.substring(0, selection_start)
                         + tag
                         + this.sms.substring(selection_end, this.sms.length)

                this.$nextTick(() => {
                    textarea.selectionStart = selection_start + tag.length
                    textarea.selectionEnd = textarea.selectionStart
                })
            } else {
                this.sms += tag
            }
        },
        
        submit() {
            this.error = null
            this.processing = true

            const payload = {
                uuid: this.uuid,
                sms: this.sms
            }

            this.$store.dispatch('updateDigitalCommsTemplateSMS', payload).then(() => {
                this.initSMS()

                this.processing = false
            }).catch(error => {
                this.error = errMessage(error)

                this.processing = false
            })
        },
    },

    computed: {
        ...mapGetters([
            'getDigitalCommsTemplateByUUID',
        ]),

        uuid() {
            return this.$route.params.uuid
        },

        template() {
            return this.getDigitalCommsTemplateByUUID(this.uuid)
        },

        is_changed() {
            return this.sms != this.original
        },
    },
}
</script>

<style lang="scss">
.page-digital-comms-template-sms {
    .board {
        padding: 24px;
        border-radius: $border-radius-secondary;
        background: var(--color-component-bg-primary);
        box-shadow: var(--box-shadow-primary);
        position: relative;

        .app-error {
            margin-bottom: 16px;
        }

        .app-textarea {
            width: 655px;
            height: 144px;
            max-width: 100%;
        }

        .tags {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-wrap: wrap;
            margin: 12px -12px 0;

            .btn {
                flex-shrink: 0;
                margin: 12px 12px 0;

                // &:first-child { margin: 0; }
            }
        }

        &>.btn {
            margin-top: 145px;
        }
    }
}

@media (max-width: $tablet-size) {
    .page-digital-comms-template-sms {
        .board {
            padding: 16px;
            
            .tags {
                margin: 12px -6px 0;

                .btn {
                    margin: 12px 6px 0;
                }
            }

            &>.btn {
                margin-top: 80px;
            }
        }
    }
}

@media (max-width: $mobile-size) {
    .page-digital-comms-template-sms {
        .board {
            padding: 16px 8px 8px;
            
            .tags {
                .btn {
                    flex-grow: 1;
                    max-width: calc(50% - 12px);
                }
            }

            &>.btn {
                max-width: 100%;
                margin-top: 48px;
            }
        }
    }
}
</style>